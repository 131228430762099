import React from "react"
import styled from "styled-components"

const WrapperDiv = styled.div`
    max-width: 1110px;
    margin: 60px auto 100px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;

    @media only screen and (max-width: 1024px) {
        margin-top: 40px;
        margin-bottom: 100px;
    }
`;

const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;

    h2 {
        width: 100%;
        margin-bottom: 15px;
    }

    @media only screen and (max-width: 1024px) {
        margin-bottom: 40px;
    }
`;

const DetailFlex = styled.div`
    display: flex;
    justify-content: center;
    background: #E5F5FB;
    margin-bottom: 100px;
    @media only screen and (max-width: 1140px) {
        padding: 0 20px;
        margin-bottom: 60px;
    }
`;

const DetailText = styled.p `
    padding: 57px 0 60px;
    max-width: 1110px;

    @media only screen and (max-width: 1140px) {
        padding: 40px 0 100px;
    }
`

const MethodologyDiv = styled.div `
    max-width: 1110px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    > div {
        width: calc(100%/3);
        padding: 40px;
        box-sizing: border-box;

        @media only screen and (max-width: 700px) {
            width: 100%;
        }

        > div {
            width: 50%;
            float: left;
            padding-left: 15px;
            &:first-of-type {
                padding-left: 0;
                padding-right: 15px;
            }
        }

        h5 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 5px;
        }

        &.preparation {
            background-color: #EBF1F7;
        }

        &.prototyping {
            background-color: #EAF8F5;
        }
        &.production {
            background-color: #E5F5FB;

            #supportSVG {
                transform: rotate(45deg);
                transform-origin: 50% 50%;
            }
        }
    }
`

const SVGContainer = styled.div `
    width: 104px;
    height: 104px;
    margin: 35px auto 15px;
`

const planSVG = () => (
    <svg width="88px" height="88px" viewBox="0 0 88 88" version="1.1">
    <title>methodology / plan</title>
    <g id="Website" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="ABOUT-US---Desktop" transform="translate(-224.000000, -1234.000000)">
            <rect fill="#FFFFFF" x="0" y="0" width="1440" height="3705"></rect>
            <g id="Methodology" transform="translate(164.000000, 1013.000000)">
                <rect id="Rectangle" fill="#EBF1F7" x="1" y="109" width="370" height="420"></rect>
                <g id="Group" transform="translate(52.000000, 213.000000)" stroke-width="1.8">
                    <g id="plan.icon" transform="translate(9.043478, 9.043478)">
                        <polygon id="Stroke-124" stroke="#33BBA1" stroke-linejoin="round" points="38.4351443 66.77424 50.3571617 66.77424 50.3571617 54.0374052 38.4351443 54.0374052"></polygon>
                        <polygon id="Stroke-125" stroke="#3D7BB2" stroke-linecap="round" stroke-linejoin="round" points="38.4351443 48.3409183 50.3571617 48.3409183 50.3571617 35.6031791 38.4351443 35.6031791"></polygon>
                        <polygon id="Stroke-126" stroke="#3D7BB2" stroke-linecap="round" stroke-linejoin="round" points="21.6658226 20.8541704 66.7801183 20.8541704 66.7801183 10.4469357 21.6658226 10.4469357"></polygon>
                        <path d="M42.3163339,85.6542191 L76.5278122,85.6560278 C81.6744557,85.7862539 85.8091339,81.5439583 85.8091339,75.4133843 L34.1717774,75.4133843" id="Stroke-127" stroke="#3D7BB2" stroke-linecap="round"></path>
                        <line x1="24.1711374" y1="85.5880209" x2="33.3294678" y2="85.5889252" id="Stroke-128" stroke="#3D7BB2" stroke-linecap="round"></line>
                        <path d="M15.4605496,51.2139409 L15.4605496,74.2874713 L15.5618365,76.3710887 C15.5618365,81.5177322 19.7344974,85.6885843 24.8802365,85.6885843 C30.0259757,85.6885843 34.1986365,81.5177322 34.1986365,76.3710887 C34.1986365,76.0618017 34.1832626,75.7561322 34.1534191,75.4549843" id="Stroke-129" stroke="#3D7BB2" stroke-linecap="round"></path>
                        <polyline id="Stroke-130" stroke="#3D7BB2" stroke-linecap="round" points="33.9299548 3.34708174 15.4604591 3.34708174 15.4604591 39.8302817"></polyline>
                        <polyline id="Stroke-131" stroke="#3D7BB2" stroke-linecap="round" points="72.9290504 66.8695583 72.9290504 3.07505391 51.8695026 3.07505391"></polyline>
                        <line x1="44.3281461" y1="21.7944209" x2="44.3281461" y2="34.6406817" id="Stroke-132" stroke="#33BBA1" stroke-linejoin="round"></line>
                        <path d="M61.8489809,53.1295304 L61.8489809,33.5078957 C61.8489809,30.994713 59.7933983,28.9391304 57.2802157,28.9391304 L31.3480417,28.9391304 C28.8357635,28.9391304 26.7801809,30.994713 26.7801809,33.5078957 L26.7801809,53.1557565" id="Stroke-133" stroke="#33BBA1" stroke-linejoin="round"></path>
                        <path d="M0.544146087,67.3871165 L0.544146087,71.2432557 C0.544146087,73.69856 9.47186783,73.69856 9.47186783,71.2432557 L9.47186783,14.5451687 C9.47186783,12.0907687 7.46240696,10.0822122 5.0089113,10.0822122 L5.00800696,10.0822122 C2.55360696,10.0822122 0.544146087,12.0907687 0.544146087,14.5451687 L0.544146087,58.4295513" id="Stroke-134" stroke="#3D7BB2" stroke-linecap="round"></path>
                        <path d="M0.544146087,71.2429843 C0.544146087,73.6982887 9.47186783,73.6982887 9.47186783,71.2429843" id="Stroke-135" stroke="#3D7BB2" stroke-linecap="round"></path>
                        <path d="M0.544146087,19.0407722 C0.544146087,21.4960765 9.47186783,21.4960765 9.47186783,19.0407722" id="Stroke-136" stroke="#3D7BB2" stroke-linecap="round"></path>
                        <polyline id="Stroke-137" stroke="#3D7BB2" stroke-linecap="round" stroke-linejoin="round" points="0.529133913 71.4505322 4.97852522 84.4921322 9.37908174 71.6341148"></polyline>
                        <line x1="72.8910678" y1="71.1749774" x2="72.9923548" y2="71.1749774" id="Stroke-138" stroke="#33BBA1" stroke-linecap="round" stroke-linejoin="round"></line>
                        <line x1="37.5269983" y1="85.5941704" x2="37.6282852" y2="85.5941704" id="Stroke-139" stroke="#33BBA1" stroke-linecap="round" stroke-linejoin="round"></line>
                        <line x1="15.3475061" y1="45.4620174" x2="15.448793" y2="45.4620174" id="Stroke-140" stroke="#33BBA1" stroke-linecap="round" stroke-linejoin="round"></line>
                        <line x1="0.401078261" y1="63.2760417" x2="0.503269565" y2="63.2760417" id="Stroke-141" stroke="#33BBA1" stroke-linecap="round" stroke-linejoin="round"></line>
                        <polygon id="Stroke-142" stroke="#3D7BB2" stroke-linecap="round" stroke-linejoin="round" points="55.8004313 66.77424 67.723353 66.77424 67.723353 54.0374052 55.8004313 54.0374052"></polygon>
                        <polygon id="Stroke-143" stroke="#3D7BB2" stroke-linecap="round" stroke-linejoin="round" points="21.068953 66.77424 32.9918748 66.77424 32.9918748 54.0374052 21.068953 54.0374052"></polygon>
                        <path d="M46.3134609,2.93641739 C46.3134609,4.55791304 44.9985391,5.87283478 43.3770435,5.87283478 C41.7555478,5.87283478 40.4406261,4.55791304 40.4406261,2.93641739 C40.4406261,1.31401739 41.7555478,-1.28515556e-13 43.3770435,-1.28515556e-13 C44.9985391,-1.28515556e-13 46.3134609,1.31401739 46.3134609,2.93641739 Z" id="Stroke-144" stroke="#33BBA1" stroke-linejoin="round"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

const designSVG = () => (
<svg width="76px" height="97px" viewBox="0 0 76 97" version="1.1">
<title>methodology / design</title>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g id="icon-/-methodology-/-design" transform="translate(-20.000000, -9.000000)" stroke-width="2">
            <g id="design.icon" transform="translate(21.000000, 10.000000)">
                <line x1="37.7458" y1="56.5185" x2="37.7458" y2="37.6985" id="Stroke-89" stroke="#33BBA1" stroke-linejoin="round"></line>
                <polygon id="Stroke-90" stroke="#3D7BB2" stroke-linejoin="round" points="17.507 94.364 58.272 94.364 58.272 84.586 17.507 84.586"></polygon>
                <polyline id="Stroke-91" stroke="#3D7BB2" stroke-linejoin="round" points="52.1071 83.9433 61.0561 65.6843 37.6731 29.5263 14.7221 65.5403 23.4561 84.0153"></polyline>
                <line x1="49.3649" y1="81.0566" x2="55.4169" y2="69.1186" id="Stroke-92" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="25.9811" y1="80.9121" x2="20.4651" y2="69.0381" id="Stroke-93" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="54.4548" y1="14.4834" x2="45.3238" y2="14.4834" id="Stroke-94" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="30.7643" y1="14.4834" x2="19.0393" y2="14.4834" id="Stroke-95" stroke="#33BBA1" stroke-linejoin="round"></line>
                <path d="M33.651,61.4004 C33.651,58.9024 35.675,56.8784 38.172,56.8784 C40.67,56.8784 42.694,58.9024 42.694,61.4004 C42.694,63.8974 40.67,65.9214 38.172,65.9214 C35.675,65.9214 33.651,63.8974 33.651,61.4004 Z" id="Stroke-96" stroke="#33BBA1" stroke-linejoin="round"></path>
                <line x1="54.0798" y1="86.9365" x2="54.0798" y2="85.9285" id="Stroke-97" stroke="#3D7BB2" stroke-linejoin="round"></line>
                <line x1="54.0798" y1="89.5908" x2="54.0798" y2="89.3938" id="Stroke-98" stroke="#3D7BB2"></line>
                <line x1="54.0798" y1="93.4902" x2="54.0798" y2="92.2642" id="Stroke-99" stroke="#3D7BB2" stroke-linejoin="round"></line>
                <line x1="48.6676" y1="86.9365" x2="48.6676" y2="85.9285" id="Stroke-100" stroke="#3D7BB2" stroke-linejoin="round"></line>
                <line x1="48.6676" y1="89.5908" x2="48.6676" y2="89.3938" id="Stroke-101" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="48.6676" y1="93.4902" x2="48.6676" y2="92.2642" id="Stroke-102" stroke="#3D7BB2" stroke-linejoin="round"></line>
                <line x1="43.2546" y1="86.9365" x2="43.2546" y2="85.9285" id="Stroke-103" stroke="#3D7BB2" stroke-linejoin="round"></line>
                <line x1="43.2546" y1="89.5908" x2="43.2546" y2="89.3938" id="Stroke-104" stroke="#3D7BB2"></line>
                <line x1="43.2546" y1="93.4902" x2="43.2546" y2="92.2642" id="Stroke-105" stroke="#3D7BB2" stroke-linejoin="round"></line>
                <line x1="37.8415" y1="86.9365" x2="37.8415" y2="85.9285" id="Stroke-106" stroke="#3D7BB2" stroke-linejoin="round"></line>
                <line x1="37.8415" y1="89.5908" x2="37.8415" y2="89.3938" id="Stroke-107" stroke="#3D7BB2"></line>
                <line x1="37.8415" y1="93.4902" x2="37.8415" y2="92.2642" id="Stroke-108" stroke="#3D7BB2" stroke-linejoin="round"></line>
                <line x1="32.4284" y1="86.9365" x2="32.4284" y2="85.9285" id="Stroke-109" stroke="#3D7BB2" stroke-linejoin="round"></line>
                <line x1="32.4284" y1="89.5908" x2="32.4284" y2="89.3938" id="Stroke-110" stroke="#3D7BB2"></line>
                <line x1="32.4284" y1="93.4902" x2="32.4284" y2="92.2642" id="Stroke-111" stroke="#3D7BB2" stroke-linejoin="round"></line>
                <line x1="27.0153" y1="86.9365" x2="27.0153" y2="85.9285" id="Stroke-112" stroke="#3D7BB2" stroke-linejoin="round"></line>
                <line x1="27.0153" y1="89.5908" x2="27.0153" y2="89.3938" id="Stroke-113" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="18.9792" y1="66.0986" x2="18.9792" y2="65.9016" id="Stroke-114" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="56.6794" y1="66.0986" x2="56.6794" y2="65.9016" id="Stroke-115" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="27.0153" y1="93.4902" x2="27.0153" y2="92.2642" id="Stroke-116" stroke="#3D7BB2" stroke-linejoin="round"></line>
                <line x1="21.6032" y1="86.9365" x2="21.6032" y2="85.9285" id="Stroke-117" stroke="#3D7BB2" stroke-linejoin="round"></line>
                <line x1="21.6032" y1="89.5908" x2="21.6032" y2="89.3938" id="Stroke-118" stroke="#3D7BB2"></line>
                <line x1="21.6032" y1="93.4902" x2="21.6032" y2="92.2642" id="Stroke-119" stroke="#3D7BB2" stroke-linejoin="round"></line>
                <line x1="37.2311" y1="-2.27373675e-13" x2="37.2311" y2="7.096" id="Stroke-120" stroke="#33BBA1" stroke-linejoin="round"></line>
                <polygon id="Stroke-121" stroke="#3D7BB2" stroke-linejoin="round" points="30.945 21.429 44.654 21.429 44.654 7.719 30.945 7.719"></polygon>
                <polygon id="Stroke-122" stroke="#3D7BB2" stroke-linejoin="round" points="3.71258579e-13 21.429 13.709 21.429 13.709 7.719 3.71258579e-13 7.719"></polygon>
                <polygon id="Stroke-123" stroke="#3D7BB2" stroke-linejoin="round" points="60.02 21.429 73.729 21.429 73.729 7.719 60.02 7.719"></polygon>
            </g>
        </g>
    </g>
</svg>
)

const buildSVG = () => (
    <svg width="82px" height="96px" viewBox="0 0 82 96" version="1.1">
    <title>methodology / build</title>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g id="icon-/-methodology-/-build" transform="translate(-17.000000, -9.000000)" stroke-width="2">
            <g id="build.icon" transform="translate(18.000000, 10.000000)">
                <polygon id="Stroke-61" stroke="#3D7BB2" stroke-linejoin="round" points="18.3528 80.5022 29.5998 80.5022 29.5998 68.4852 18.3528 68.4852"></polygon>
                <polygon id="Stroke-63" stroke="#3D7BB2" stroke-linejoin="round" points="36.5288 80.5022 47.7758 80.5022 47.7758 68.4852 36.5288 68.4852"></polygon>
                <polygon id="Stroke-64" stroke="#3D7BB2" stroke-linejoin="round" points="54.7048 80.5022 65.9518 80.5022 65.9518 68.4852 54.7048 68.4852"></polygon>
                <line x1="19.5522" y1="93.9129" x2="19.5522" y2="93.7149" id="Stroke-65" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="52.6313" y1="0.5985" x2="52.6313" y2="0.4015" id="Stroke-66" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="79.4302" y1="14.119" x2="79.4302" y2="13.921" id="Stroke-67" stroke="#3D7BB2"></line>
                <line x1="79.4302" y1="59.1697" x2="79.4302" y2="58.9727" id="Stroke-68" stroke="#3D7BB2"></line>
                <polyline id="Stroke-69" stroke="#3D7BB2" points="58.1255 0.6473 61.8125 0.6473 61.8125 5.0963"></polyline>
                <polyline id="Stroke-70" stroke="#3D7BB2" points="4.81 85.0682 8.8817842e-14 85.0682 8.8817842e-14 0.6472 47.233 0.6472"></polyline>
                <line x1="18.2299" y1="16.8826" x2="34.5839" y2="16.8826" id="Stroke-71" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="18.2299" y1="24.0672" x2="35.6669" y2="24.0672" id="Stroke-72" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="18.2299" y1="31.3729" x2="65.4909" y2="31.3729" id="Stroke-73" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="18.2299" y1="37.9276" x2="65.4909" y2="37.9276" id="Stroke-74" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="18.2299" y1="45.1424" x2="65.4909" y2="45.1424" id="Stroke-75" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="18.2299" y1="52.3582" x2="65.4909" y2="52.3582" id="Stroke-76" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="18.2299" y1="59.5731" x2="65.4909" y2="59.5731" id="Stroke-77" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="79.4302" y1="64.3807" x2="79.4302" y2="67.7507" id="Stroke-78" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="79.4302" y1="49.5897" x2="79.4302" y2="52.9587" id="Stroke-79" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="79.4302" y1="42.1932" x2="79.4302" y2="45.5632" id="Stroke-80" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="79.4302" y1="34.7977" x2="79.4302" y2="38.1677" id="Stroke-81" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="79.4302" y1="27.4022" x2="79.4302" y2="30.7722" id="Stroke-82" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="79.4302" y1="20.0067" x2="79.4302" y2="23.3757" id="Stroke-83" stroke="#33BBA1" stroke-linejoin="round"></line>
                <line x1="70.1577" y1="5.1737" x2="73.1987" y2="5.1737" id="Stroke-84" stroke="#33BBA1" stroke-linejoin="round"></line>
                <polyline id="Stroke-85" stroke="#33BBA1" stroke-linejoin="round" points="79.4302 8.5848 79.4302 5.2148 76.7582 5.2148"></polyline>
                <line x1="73.0561" y1="71.8738" x2="79.2491" y2="71.8738" id="Stroke-86" stroke="#33BBA1" stroke-linejoin="round"></line>
                <polyline id="Stroke-87" stroke="#33BBA1" stroke-linejoin="round" points="11.0639 89.6375 4.8099 89.6375 4.8099 5.2175 66.6229 5.2175 66.6229 9.6665"></polyline>
                <polyline id="Stroke-88" stroke="#3D7BB2" points="14.8491 93.9666 10.9441 93.9666 10.9441 9.5466 72.7551 9.5466 72.7551 93.9666 24.1891 93.9666"></polyline>
            </g>
        </g>
    </g>
</svg>
)

const trainSVG = () => (
    <svg width="81px" height="96px" viewBox="0 0 81 96" version="1.1">
        <title>methodology / test and train</title>
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="icon-/-methodology-/-test_train" transform="translate(-18.000000, -9.000000)" stroke-width="2">
                <g id="test_train.icon" transform="translate(19.000000, 10.000000)">
                    <line x1="26.173" y1="11.3349" x2="26.173" y2="11.1379" id="Stroke-191" stroke="#33BBA1" stroke-linecap="round" stroke-linejoin="round"></line>
                    <line x1="70.6183" y1="93.8203" x2="70.6183" y2="93.6223" id="Stroke-192" stroke="#33BBA1" stroke-linecap="round" stroke-linejoin="round"></line>
                    <polyline id="Stroke-193" stroke="#3D7BB2" stroke-linecap="round" points="65.8126 93.8203 16.9986 93.8203 16.9986 11.2443 20.9486 11.2443"></polyline>
                    <polyline id="Stroke-194" stroke="#3D7BB2" stroke-linecap="round" points="65.0304 11.2441 78.8094 11.2441 78.8094 93.8201 75.4024 93.8201"></polyline>
                    <line x1="57.3449" y1="22.2676" x2="72.4179" y2="22.2676" id="Stroke-195" stroke="#33BBA1" stroke-linecap="round" stroke-linejoin="round"></line>
                    <line x1="57.3449" y1="29.4531" x2="72.4159" y2="29.4531" id="Stroke-196" stroke="#33BBA1" stroke-linecap="round" stroke-linejoin="round"></line>
                    <line x1="57.3449" y1="36.7588" x2="72.3659" y2="36.7588" id="Stroke-197" stroke="#33BBA1" stroke-linecap="round" stroke-linejoin="round"></line>
                    <line x1="57.3449" y1="43.3125" x2="72.3659" y2="43.3125" id="Stroke-198" stroke="#33BBA1" stroke-linecap="round" stroke-linejoin="round"></line>
                    <line x1="57.3449" y1="50.5283" x2="72.3659" y2="50.5283" id="Stroke-199" stroke="#33BBA1" stroke-linecap="round" stroke-linejoin="round"></line>
                    <line x1="57.3449" y1="57.7431" x2="72.3659" y2="57.7431" id="Stroke-200" stroke="#33BBA1" stroke-linecap="round" stroke-linejoin="round"></line>
                    <polygon id="Stroke-201" stroke="#3D7BB2" stroke-linecap="round" stroke-linejoin="round" points="30.9476 16.175 64.8606 16.175 64.8606 6.795 30.9476 6.795"></polygon>
                    <path d="M35.5177,7.083 L35.5177,5.425 C35.5177,2.441 37.9577,-1.84741111e-13 40.9417,-1.84741111e-13 L54.8667,-1.84741111e-13 C57.8497,-1.84741111e-13 60.2897,2.441 60.2897,5.425 L60.2897,7.083" id="Stroke-202" stroke="#3D7BB2"></path>
                    <polyline id="Stroke-203" stroke="#33BBA1" stroke-linecap="round" stroke-linejoin="round" points="31.0207 39.498 34.1317 44.729 44.5037 35.801"></polyline>
                    <path d="M25.3917,29.876 C28.1377,26.502 32.3247,24.346 37.0137,24.346 C45.2857,24.346 51.9907,31.052 51.9907,39.323 C51.9907,40.992 51.7167,42.599 51.2127,44.099" id="Stroke-204" stroke="#3D7BB2" stroke-linecap="round"></path>
                    <path d="M49.2843,48.039 C46.5383,51.413 42.3523,53.568 37.6623,53.568 C29.3913,53.568 22.6853,46.863 22.6853,38.592 C22.6853,36.922 22.9593,35.316 23.4633,33.816" id="Stroke-205" stroke="#3D7BB2" stroke-linecap="round"></path>
                    <polyline id="Stroke-206" stroke="#3D7BB2" stroke-linecap="round" points="25.6173 25.2773 25.3273 29.9743 29.8043 30.2003"></polyline>
                    <polyline id="Stroke-207" stroke="#3D7BB2" stroke-linecap="round" points="49.8361 52.0996 49.2031 47.4366 44.7681 48.0906"></polyline>
                    <polygon id="Stroke-208" stroke="#3D7BB2" stroke-linecap="round" stroke-linejoin="round" points="24.3046 81.806 35.5516 81.806 35.5516 69.789 24.3046 69.789"></polygon>
                    <polygon id="Stroke-209" stroke="#3D7BB2" stroke-linecap="round" stroke-linejoin="round" points="42.4806 81.806 53.7276 81.806 53.7276 69.789 42.4806 69.789"></polygon>
                    <polygon id="Stroke-210" stroke="#3D7BB2" stroke-linecap="round" stroke-linejoin="round" points="60.6566 81.806 71.9036 81.806 71.9036 69.789 60.6566 69.789"></polygon>
                    <path d="M0.6017,73.5312 L0.6017,77.7952 C0.6017,80.5102 10.4737,80.5102 10.4737,77.7952 L10.4737,15.1012 C10.4737,12.3862 8.2517,10.1652 5.5377,10.1652 C2.8227,10.1652 0.6017,12.3862 0.6017,15.1012 L0.6017,63.6262" id="Stroke-211" stroke="#3D7BB2" stroke-linecap="round"></path>
                    <path d="M0.6017,77.7949 C0.6017,80.5099 10.4737,80.5099 10.4737,77.7949" id="Stroke-212" stroke="#3D7BB2" stroke-linecap="round"></path>
                    <path d="M0.6017,20.0713 C0.6017,22.7863 10.4737,22.7863 10.4737,20.0713" id="Stroke-213" stroke="#3D7BB2" stroke-linecap="round"></path>
                    <polyline id="Stroke-214" stroke="#3D7BB2" stroke-linecap="round" stroke-linejoin="round" points="0.5851 78.0244 5.5051 92.4454 10.3711 78.2274"></polyline>
                    <line x1="0.4435" y1="68.9853" x2="0.5565" y2="68.9853" id="Stroke-215" stroke="#33BBA1" stroke-linecap="round" stroke-linejoin="round"></line>
                </g>
            </g>
        </g>
    </svg>
)

const deploySVG = () => (
    <svg width="114px" height="114px" viewBox="0 0 114 114" version="1.1">
        <title>methodology / deploy</title>
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linejoin="round">
            <g id="icon-/-methodology-/-deploy" transform="translate(-1.000000, -1.000000)" stroke-width="2">
                <g id="deploy.icon" transform="translate(2.000000, 2.000000)">
                    <line x1="57.0494" y1="20.149" x2="57.0494" y2="19.918" id="Stroke-145" stroke="#3D7BB2" stroke-linecap="round"></line>
                    <line x1="91.2877" y1="54.6265" x2="91.2877" y2="54.3945" id="Stroke-146" stroke="#3D7BB2" stroke-linecap="round"></line>
                    <line x1="20.8883" y1="54.5835" x2="20.8883" y2="54.3525" id="Stroke-147" stroke="#3D7BB2" stroke-linecap="round"></line>
                    <line x1="56.7867" y1="91.1138" x2="56.7867" y2="90.8828" id="Stroke-148" stroke="#3D7BB2" stroke-linecap="round"></line>
                    <line x1="32.3687" y1="41.1402" x2="78.3567" y2="41.1402" id="Stroke-149" stroke="#33BBA1" stroke-linecap="round"></line>
                    <line x1="32.3687" y1="49.8657" x2="78.3487" y2="49.8657" id="Stroke-150" stroke="#33BBA1" stroke-linecap="round"></line>
                    <line x1="32.3687" y1="58.7378" x2="78.1977" y2="58.7378" id="Stroke-151" stroke="#33BBA1" stroke-linecap="round"></line>
                    <line x1="57.0543" y1="0.0357" x2="57.0543" y2="15.8867" id="Stroke-152" stroke="#33BBA1" stroke-linecap="round"></line>
                    <polyline id="Stroke-153" stroke="#33BBA1" stroke-linecap="round" points="48.7428 8.481 57.0178 -1.13686838e-13 65.5368 8.481"></polyline>
                    <line x1="57.0543" y1="111.2212" x2="57.0543" y2="95.3702" id="Stroke-154" stroke="#33BBA1" stroke-linecap="round"></line>
                    <polyline id="Stroke-155" stroke="#33BBA1" stroke-linecap="round" points="48.7428 102.7759 57.0178 111.2569 65.5368 102.7759"></polyline>
                    <line x1="111.3736" y1="54.5796" x2="95.5226" y2="54.5796" id="Stroke-156" stroke="#33BBA1" stroke-linecap="round"></line>
                    <polyline id="Stroke-157" stroke="#33BBA1" stroke-linecap="round" points="102.9283 46.2681 111.4093 54.5431 102.9283 63.0621"></polyline>
                    <line x1="0.0357" y1="54.5796" x2="15.8867" y2="54.5796" id="Stroke-158" stroke="#33BBA1" stroke-linecap="round"></line>
                    <polyline id="Stroke-159" stroke="#33BBA1" stroke-linecap="round" points="8.481 46.2681 -1.13686838e-13 54.5431 8.481 63.0621"></polyline>
                    <polygon id="Stroke-160" stroke="#3D7BB2" stroke-linecap="round" points="86.608 86.4116 24.835 86.4116 24.835 32.0866 24.835 24.6386 86.608 24.6386"></polygon>
                    <line x1="24.1773" y1="32.9624" x2="86.5343" y2="32.9624" id="Stroke-161" stroke="#3D7BB2"></line>
                    <line x1="66.5465" y1="25.3687" x2="66.5465" y2="32.6707" id="Stroke-162" stroke="#3D7BB2"></line>
                    <line x1="72.8443" y1="25.3687" x2="72.8443" y2="32.6707" id="Stroke-163" stroke="#3D7BB2"></line>
                    <line x1="79.1422" y1="25.3687" x2="79.1422" y2="32.6707" id="Stroke-164" stroke="#3D7BB2"></line>
                    <polygon id="Stroke-165" stroke="#3D7BB2" stroke-linecap="round" points="32.2823 79.2716 43.2043 79.2716 43.2043 67.6026 32.2823 67.6026"></polygon>
                    <polygon id="Stroke-166" stroke="#3D7BB2" stroke-linecap="round" points="49.9323 79.2716 60.8543 79.2716 60.8543 67.6026 49.9323 67.6026"></polygon>
                    <polygon id="Stroke-167" stroke="#3D7BB2" stroke-linecap="round" points="67.5813 79.2716 78.5023 79.2716 78.5023 67.6026 67.5813 67.6026"></polygon>
                </g>
            </g>
        </g>
    </svg>
)

const supportSVG = () => (
    <svg width="111px" height="111px" viewBox="0 0 111 111" version="1.1" id="supportSVG">
        <title>methodology / support</title>
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" >
            <g id="icon-/-methodology-/-support" transform="translate(-2.000000, -2.000000)" stroke-width="2.244">
                <g id="support.icon" transform="translate(4.000000, 4.000000)">
                    <path d="M32.808963,13.655862 L41.459583,5.005242 C48.133239,-1.668414 59.054787,-1.668414 65.728443,5.005242 L74.300523,13.578444" id="Stroke-168" stroke="#33BBA1"></path>
                    <path d="M14.27184,74.9932458 L5.005242,65.7277698 C-1.668414,59.0541138 -1.668414,48.1336878 5.005242,41.4589098 L13.945338,32.5210578" id="Stroke-169" stroke="#33BBA1"></path>
                    <path d="M74.8214676,93.0888618 L65.7287796,102.18155 C59.0540016,108.855206 48.1335756,108.855206 41.4599196,102.18155 L32.2886916,93.0103218" id="Stroke-170" stroke="#33BBA1"></path>
                    <path d="M93.1645968,32.4427422 L102.182111,41.4591342 C108.854645,48.1327902 108.854645,59.0543382 102.182111,65.7279942 L93.3766548,74.5323282" id="Stroke-171" stroke="#33BBA1"></path>
                    <line x1="74.3009718" y1="13.5781074" x2="74.6858178" y2="13.9640754" id="Stroke-172" stroke="#33BBA1"></line>
                    <line x1="74.322963" y1="25.2538638" x2="74.460969" y2="25.1147358" id="Stroke-173" stroke="#3D7BB2"></line>
                    <line x1="77.4840858" y1="22.1223618" x2="80.9768718" y2="18.6295758" id="Stroke-174" stroke="#33BBA1"></line>
                    <line x1="68.081838" y1="31.5246096" x2="71.574624" y2="28.0318236" id="Stroke-175" stroke="#33BBA1"></line>
                    <line x1="80.3372196" y1="34.620993" x2="88.8071976" y2="26.151015" id="Stroke-176" stroke="#33BBA1"></line>
                    <line x1="33.0795894" y1="81.8787354" x2="32.9415834" y2="82.0178634" id="Stroke-177" stroke="#3D7BB2"></line>
                    <line x1="29.9184666" y1="85.0102374" x2="26.4256806" y2="88.5030234" id="Stroke-178" stroke="#33BBA1"></line>
                    <line x1="39.3207144" y1="75.6079896" x2="35.8279284" y2="79.1007756" id="Stroke-179" stroke="#33BBA1"></line>
                    <line x1="27.0652206" y1="72.511494" x2="18.5952426" y2="80.981472" id="Stroke-180" stroke="#33BBA1"></line>
                    <line x1="82.2163452" y1="74.1386184" x2="82.3554732" y2="74.2766244" id="Stroke-181" stroke="#3D7BB2"></line>
                    <line x1="85.3489692" y1="77.2997412" x2="88.8417552" y2="80.7925272" id="Stroke-182" stroke="#33BBA1"></line>
                    <line x1="75.9467214" y1="67.8974934" x2="79.4395074" y2="71.3902794" id="Stroke-183" stroke="#33BBA1"></line>
                    <line x1="72.849216" y1="80.1529872" x2="81.319194" y2="88.6229652" id="Stroke-184" stroke="#33BBA1"></line>
                    <line x1="25.302222" y1="32.6059932" x2="25.164216" y2="32.4679872" id="Stroke-185" stroke="#3D7BB2"></line>
                    <line x1="22.17072" y1="29.4449826" x2="18.677934" y2="25.9521966" id="Stroke-186" stroke="#33BBA1"></line>
                    <line x1="31.5729678" y1="38.8471182" x2="28.0801818" y2="35.3543322" id="Stroke-187" stroke="#33BBA1"></line>
                    <line x1="34.6694634" y1="26.5917366" x2="26.1994854" y2="18.1217586" id="Stroke-188" stroke="#33BBA1"></line>
                    <path d="M72.2980896,72.1948656 C61.9958856,82.4970696 45.2949156,82.4970696 34.9927116,72.1948656 C24.6905076,61.8926616 24.6905076,45.1916916 34.9927116,34.8894876 C45.2949156,24.5872836 61.9958856,24.5872836 72.2980896,34.8894876 C82.6002936,45.1916916 82.6002936,61.8926616 72.2980896,72.1948656 Z" id="Stroke-189" stroke="#3D7BB2"></path>
                    <path d="M85.3588428,85.2567408 C67.8444228,102.771161 39.4466028,102.771161 21.9310608,85.2567408 C4.4155188,67.7411988 4.4155188,39.3433788 21.9310608,21.8278368 C39.4466028,4.3122948 67.8444228,4.3122948 85.3588428,21.8278368 C102.874385,39.3433788 102.874385,67.7411988 85.3588428,85.2567408 Z" id="Stroke-190" stroke="#3D7BB2"></path>
                </g>
            </g>
        </g>
    </svg>
)

const Methodology = () => {
    return (
        <MethodologyDiv>
        <div className="preparation">
            <h3 className="text-center text-blue">PREPARATION</h3>
            <div>
                <SVGContainer>{planSVG()}</SVGContainer>
                <h5 className="text-blue">Plan</h5>
                <p>
                    Project Initiation<br/>
                    Onboarding<br/>
                    Project Kick-off
                </p>
            </div>
            <div>
                <SVGContainer>{designSVG()}</SVGContainer>
                <h5 className="text-blue">Design</h5>
                <p>
                Document Requirements<br/>
                Review Design<br/>
                Define Scope
                </p>
            </div>
        </div>
        <div className="prototyping">
            <h3 className="text-center text-dark-green">PROTOTYPING</h3>
            <div>
                <SVGContainer>{buildSVG()}</SVGContainer>
                <h5 className="text-dark-green">Build</h5>
                <p>
                    Project Initiation<br/>
                    Onboarding<br/>
                    Project Kick-off
                </p>
            </div>
            <div>
                <SVGContainer>{trainSVG()}</SVGContainer>
                <h5 className="text-dark-green">Test & Train</h5>
                <p>
                Configure / Build (iterative)<br/>
                Walk-Throughs<br/>
                Refine
                </p>
            </div>
        </div>
        <div className="production">
            <h3 className="text-center text-light-blue">PRODUCTION</h3>
            <div>
                <SVGContainer>{deploySVG()}</SVGContainer>
                <h5 className="text-light-blue">Deploy</h5>
                <p>
                    Go-live Decision<br/>
                    Deployment<br/>
                    Data Migration<br/>
                    User Training
                </p>
            </div>
            <div>
                <SVGContainer>{supportSVG()}</SVGContainer>
                <h5 className="text-light-blue">Support</h5>
                <p>
                Adoption<br/>
                Support
                </p>
            </div>
        </div>
        </MethodologyDiv>
    )
}

const Details = () => (
    <>
        <DetailFlex>
            <DetailText className="intro">Founded in 2015, Ladd Partners offers full-service consulting for Salesforce, Certinia & Rootstock including implementations, managed services support and custom development. We focus on delivering meaningful results for our customers. We hire the best people, and treat our employees and our customers right. At the end of the day: Your success is our business.</DetailText>
        </DetailFlex>
        <WrapperDiv>
        <Flex>
            <h2 className="margin-bottom-0">Methodology</h2>
            <p className="margin-bottom-620">At Ladd Partners, we employ a consistent, proven methodology to deliver repeatable results for our customers.</p>    
        </Flex>
        {Methodology()}
        </WrapperDiv>
    </>
)

export default Details