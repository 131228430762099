import React from "react"
import Hero from "../../components/hero-about"
import AboutDetails from "../../components/about-details"
import Partners from "../../components/partners"
import Map from "../../components/map"

import "../../assets/index.css"
import Layout from "../../layouts/layout"

export default () => (
    <Layout title="About | Ladd Partners">
        <Hero/>
        <AboutDetails/>
        <Map></Map>
    <Partners alignment="left"/>
    </Layout>
)
